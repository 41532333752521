<template>
  <div class="slider-component">
    <v-card max-width="630" class="mx-auto">
      <v-carousel
        :continuous="true"
        :interval="4000"
        :cycle="cycle"
        :show-arrows="false"
        hide-delimiter-background
        delimiter-icon="mdi-checkbox-blank-circle"
        height=""
      >
        <!-- <v-carousel-item 
      v-for="(item,i) in sliderData"
      :key="i"
      :src="image_base_url + item.trans[0].image">
      </v-carousel-item>  -->
        <v-carousel-item
          v-for="(item, i) in sliderData"
          :key="i"
          :src="item.image"
        >
        </v-carousel-item>
        <!-- <v-carousel-item src="../assets/images/slider-1.png"> </v-carousel-item>
        <v-carousel-item src="../assets/images/slider-1.png"> </v-carousel-item>
        <v-carousel-item src="../assets/images/slider-1.png"> </v-carousel-item> -->
      </v-carousel>
    </v-card>
  </div>
</template>
<script>
import { ServiceFactory } from "../services/ServiceFactory";
const service = ServiceFactory.get("product");
export default {
  data() {
    return {
      cycle: true,
      sliderData: [],
      //sliderData: [
      //require("../assets/images/slider-3.jpeg"),
      //require("../assets/images/slider-2.jpeg"),
      //require("../assets/images/slider-1.png"),
      // ],

      image_base_url: "",
    };
  },
  created() {
    this.fetchAllItems();
    //console.log(this.sliderData.length);
  },
  methods: {
    async fetchAllItems() {
      this.dataLoading = true;
      const data = await service.getAllSliderData();
      this.sliderData = data;
      this.image_base_url = data.image_base_url;
      this.dataLoading = false;
    },
  },
};
</script>

<style>
.slider-component {
  border-radius: 20px;
  /* box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18); */
  height: 330px !important;
  background-color: #f5f5f5;
}
.theme--light.v-image {
  height: max-content !important;
}

.slider-component .theme--light.v-card {
  background: whitesmoke;
}
.slider-component v-carousel-item {
  border-radius: 20px;
  height: 330px !important;
  /* background-color: #f5f5f5; */
}
.v-image__image--cover {
  background-size: contain;
}
.slider-component img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  /* background-color: #f5f5f5; */
}
.slider-component .v-card > *:last-child:not(.v-btn):not(.v-chip) {
  height:unset !important;
}
.slider-component .theme--dark.v-image {
  height: max-content;
}
.slider-component .v-sheet.v-card:not(.v-sheet--outlined) {
  border-radius: 20px;
}
.slider-component .v-btn--icon.v-size--small .v-icon,
.v-btn--fab.v-size--small .v-icon {
  font-size: 10px !important;
  color: #fff;
  height: 12px;
  width: 12px;
  opacity: 1;
}
.v-item--active.v-btn--active .v-icon {
  color: #ffbe16 !important;
}
.slider-component .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.theme--dark.v-btn.v-btn--icon {
  /* margin: 0 !important; */
  height: auto !important;
  width: auto !important;
}

@media only screen and (max-width: 426px) {
  .slider-component v-carousel-item {
  height: 230px !important;
  }
  .slider-component {
  height: 210px !important;
}
.theme--light.v-image {
  height: max-content !important;
}
}
@media only screen and (max-width: 376px) {
  .slider-component v-carousel-item {
  height: 230px !important;
  }
.theme--light.v-image {
  height: max-content !important;
}
}
@media only screen and (max-width: 321px) {
  .slider-component v-carousel-item {
  height: 230px !important;
  }
  .slider-component {
  height: 210px !important;
}
.theme--light.v-image {
  height: max-content !important;
}
}

</style>